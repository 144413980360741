import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import commonAPIService from "../../common/js/common-api-service";
import router from "../../../router/router";
export default {
  data: () => ({
    name: "ServiceLevels",
    backSlash: true,
    refresh: true,
    serviceLevelTbl: false,
    selectedProject: "",
    selectedBusiness: "",
    selectedOrder: "",
    favouriteList: [],
    showAdd: false,
    projectData: [],
    businessData: [],
    orderTypeData: [],
    userId: EncryptUtility.localStorageDecrypt("userID"),
    projectSelected: true,
    businessSelected: true,
    serviceLists: false,
    headers: [
      {
        text: "Service Level",
        align: "start",
        value: "Service Level",
        class: "primary customwhite--text",
      },
      {
        text: "Project",
        value: "Project",
        class: "primary customwhite--text",
      },
      {
        text: "Domestic",
        value: "Domestic",
        class: "primary customwhite--text",
      },
      {
        text: "Active",
        value: "Active",
        class: "primary customwhite--text",
      },
      {
        text: "Preferred Carrier Code",
        value: "Order Req Carr Code",
        class: "primary customwhite--text",
      },
      {
        text: "Shipping Priority Code",
        value: "Order Req Srvc Lev Code",
        class: "primary customwhite--text",
      },
      {
        text: "Account#",
        value: "Account#",
        class: "primary customwhite--text",
      },
      {
        text: "Actions",
        value: "actions",
        class: "primary customwhite--text",
        sortable: false,
      },
    ],
    selectedBusinessData: [],
    selectedProjectData: [],
    selectedOrderTypeData: [],
    serviceLevelList: [],
    totalRecords: 0,
    exportDisabled: true,
    serviceLevelShow: false,
    serviceLevelEdit: [],
    serviceLevelAdd: [],
    searchServiceLevel: "",
    json_fields: {
      "Service Level": "Service Level",
      Project: "Project",
      Domestic: "Domestic",
      Active: "Active",
      "Preferred Carrier Code": "Order Req Carr Code",
      "Shipping Priority Code": "Order Req Srvc Lev Code",
      "Account#": "Account#",
    },
    json_meta: [
      [
        {
          key: "charset",
          value: "utf-8",
        },
      ],
    ],
    excelName: "",
    actionList: [],
  }),
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  mounted() {
    this.getProjectList();
    this.getSearch();
    router.afterEach((to) => {
      if (!to.path.includes("app-service-level-edit") && !to.path.includes("app-service-level-add") && !to.path.includes("app-service-level")) {
        EncryptUtility.localStorageRemoveItem("ASProject");
        EncryptUtility.localStorageRemoveItem("ASProjectData");
        EncryptUtility.localStorageRemoveItem("ASBusiness");
        EncryptUtility.localStorageRemoveItem("ASBusinessData");
        EncryptUtility.localStorageRemoveItem("ASOrderType");
        EncryptUtility.localStorageRemoveItem("ASOrderTypeData");
      }
    });
  },
  methods: {
    //Loading Local Storage items for search persistance
    getSearch() {
      if (EncryptUtility.localStorageDecrypt("ASProject") !== null && EncryptUtility.localStorageDecrypt("ASProject") !== undefined) {
        this.selectedProject = parseInt(EncryptUtility.localStorageDecrypt("ASProject"));
        this.selectedProjectData = JSON.parse(EncryptUtility.localStorageDecrypt("ASProjectData"));
        this.getBusinessList(this.selectedProject);
        this.projectSelected = false;
        if (EncryptUtility.localStorageDecrypt("ASBusiness") !== null && EncryptUtility.localStorageDecrypt("ASBusiness") !== undefined) {
          this.selectedBusiness = parseInt(EncryptUtility.localStorageDecrypt("ASBusiness"));
          this.selectedBusinessData = JSON.parse(EncryptUtility.localStorageDecrypt("ASBusinessData"));
          this.getOrderType();
          this.businessSelected = false;
          if (EncryptUtility.localStorageDecrypt("ASOrderType") !== null && EncryptUtility.localStorageDecrypt("ASOrderType") !== undefined) {
            this.selectedOrder = EncryptUtility.localStorageDecrypt("ASOrderType");
            this.selectedOrderTypeData = JSON.parse(EncryptUtility.localStorageDecrypt("ASOrderTypeData"));
            this.getServiceLevelsList(this.selectedOrder);
          }
        }
      }
    },
    //Get Projects List
    async getProjectList() {
      this.projectData = [];
      let data = await commonAPIService.getProject("get", this.userId);
      if (data.message !== "NA") {
        this.projectData = data.ProjectInfo;
      }
    },
    //Project Selected
    onProjectSelect() {
      this.selectedProjectData = [];
      this.selectedBusiness = "";
      this.selectedOrder = "";
      this.serviceLevelShow = false;
      this.serviceLevelList = [];
      this.totalRecords = 0;
      this.exportDisabled = true;
      this.selectedProjectData = this.projectData.filter((obj) => obj.ProjKey === this.selectedProject);
      this.getBusinessList(this.selectedProject);
      this.projectSelected = false;
      if (this.selectedProject !== null && this.selectedProject !== undefined) {
        EncryptUtility.localStorageEncrypt("ASProject", this.selectedProject.toString());
        if (this.selectedProjectData.length !== 0) {
          EncryptUtility.localStorageEncrypt("ASProjectData", JSON.stringify(this.selectedProjectData));
        }
      }
    },
    //Get Business List
    getBusinessList(selProject) {
      this.businessData = [];
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get(`/cp/business_header?Pass=2&UserId=${this.userId}`)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.businessData = responseData.filter((obj) => obj.ProjKey === selProject);
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Business Selected
    onBusinessSelect() {
      this.selectedBusinessData = [];
      this.selectedOrder = "";
      this.serviceLevelShow = false;
      this.serviceLevelList = [];
      this.totalRecords = 0;
      this.exportDisabled = true;
      this.selectedBusinessData = this.businessData.filter((obj) => obj.BuKey === this.selectedBusiness);
      this.getOrderType();
      this.businessSelected = false;
      if (this.selectedBusiness !== null && this.selectedBusiness !== undefined) {
        EncryptUtility.localStorageEncrypt("ASBusiness", this.selectedBusiness.toString());
        if (this.selectedBusinessData.length !== 0) {
          EncryptUtility.localStorageEncrypt("ASBusinessData", JSON.stringify(this.selectedBusinessData));
        }
      }
    },
    //Get Order Type List
    getOrderType() {
      this.orderTypeData = [];
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get(`/cp/warehouse_info_list?Pass=4&UserId=${this.userId}`)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.orderTypeData = responseData;
            LoaderDialog.visible = false;
            this.serviceLevelTbl = true;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Order Type Selected
    onOrderTypeSelect() {
      this.selectedOrderTypeData = [];
      this.selectedOrderTypeData = this.orderTypeData.filter((obj) => obj.code === this.selectedOrder);
      if (this.selectedOrder !== "" && this.selectedOrder !== undefined) {
        EncryptUtility.localStorageEncrypt("ASOrderType", this.selectedOrder.toString());
        if (this.selectedOrderTypeData.length !== 0) {
          EncryptUtility.localStorageEncrypt("ASOrderTypeData", JSON.stringify(this.selectedOrderTypeData));
        }
        this.getServiceLevelsList(this.selectedOrder);
      }
    },
    //Getting data based on the order type selected
    getServiceLevelsList(orderType) {
      this.serviceLevelList = [];
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let serviceLevelObj = {
        User_Id: parseInt(this.userId),
        bu_id: parseInt(this.selectedBusinessData[0].BuKey),
        sbu_id: parseInt(this.selectedBusinessData[0].SBuKey),
        ord_code: orderType,
      };
      this.totalRecords = 0;
      this.exportDisabled = true;
      this.axios
        .post("/cp/serviceLevels_list", serviceLevelObj)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responseData = JSON.parse(response.data.body.message);
            this.serviceLevelList = responseData;
            this.serviceLevelShow = true;
            if (responseData.length == 0) {
              this.totalRecords = 0;
              this.exportDisabled = true;
            } else {
              this.totalRecords = responseData.length;
              this.exportDisabled = false;
            }
            this.serviceLists = true;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          this.serviceLevelShow = true;
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Export functionality
    fileName() {
      this.excelName =
        "Service_Level_List_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".xls";
    },
    //Reset Function
    resetFunction() {
      this.selectedProject = "";
      this.selectedBusiness = "";
      this.selectedOrder = "";
      this.projectSelected = true;
      this.businessSelected = true;
      this.serviceLevelList = [];
      this.orderTypeData = [];
      this.businessData = [];
      this.projectData = [];
      this.serviceLevelTbl = false;
      this.getProjectList();
    },
    //Edit Service Levels
    serviceLevelDetails(item) {
      this.serviceLevelEdit = [];
      this.serviceLevelEdit = {
        bu_id: this.selectedBusinessData[0].BuKey,
        sbu_id: this.selectedBusinessData[0].SBuKey,
        slbu_id: item.slbu_id,
        ord_code: this.selectedOrderTypeData[0].code,
        projKey: this.selectedProjectData[0].ProjKey,
        project: this.selectedProjectData[0].Project,
        business: this.selectedBusinessData[0].Business,
        ordertype: this.selectedOrderTypeData[0].displaystring,
        serviceLevel: item["Service Level"],
      };
      this.$router.push({
        name: "appServiceLevelEdit",
        params: {
          serviceLevelsEdit: btoa(JSON.stringify(this.serviceLevelEdit)),
        },
      });
    },
    //New Service List Create
    newServiceLevelClicked() {
      this.serviceLevelAdd = [];
      this.serviceLevelAdd = {
        projKey: this.selectedProjectData[0].ProjKey,
        project: this.selectedProjectData[0].Project,
        business: this.selectedBusinessData[0].Business,
        bu_id: this.selectedBusinessData[0].BuKey,
        sbu_id: this.selectedBusinessData[0].SBuKey,
        ord_code: this.selectedOrderTypeData[0].code,
        ordertype: this.selectedOrderTypeData[0].displaystring,
      };
      this.$router.push({
        name: "appServiceLevelAdd",
        params: {
          serviceLevelsAdd: btoa(JSON.stringify(this.serviceLevelAdd)),
        },
      });
    },
    // to set the action
    actionSetter(action) {
      if (this.actionList !== null && this.actionList.length > 0) {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
    //  on Click of Favorite icon
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    // to add or remove Favourite issue
  },
  components: {
    breadcrumbComp,
  },
};
